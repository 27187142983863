.containerdiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    /* margin-top: 30vh; */
}

.text {
    margin-bottom: 20px;
    text-align: center
}

#changePassword {
    width: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#messageContact {
    margin-top: 10vh;
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    color: red;
}