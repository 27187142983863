.formBlock {
    /* width: 90vw; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin: 10px; */
}

.formBlockReason {
    width: 90vw;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.addressBlock {
    display: flex;
    flex-direction: row;
}

.phoneBlock {
    display: flex;
}

#formApplication {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AddressDistrict {
    display: flex;
}

.PhoneBlock{
    display: flex;
}