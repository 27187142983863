#formLogin {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 70vw;
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}