.InstructionUploadButtonDiv {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
}

.InstructionUploadButtonModal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}